import { createGlobalStyle} from "styled-components"
import stylesBlogIndex from './BlogIndexPage.module.css'
import stylesBlogPost from './BlogPostLayout.module.css'


export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    ${'' /* transition: all 0.50s linear; */}
  }

  #${stylesBlogIndex.titleLink} {
    color: ${({ theme }) => theme.text};
  } 

  #${stylesBlogPost.titlePost} {
    color: ${({ theme }) => theme.text};
  } 

  .${stylesBlogPost.content} :not(pre) > code {
    color: ${({ theme }) => theme.text};
    background: rgba(255,229,100,0.5);
  }

}  `
