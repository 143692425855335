export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "tuomas / anakkala.io",

  author: "Tuomas Änäkkälä",
  description: "anakkala.io",

  // The number of posts to a page on the site index.
  indexPageSize: 5,
}
