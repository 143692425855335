import React from 'react'
import { func, string } from 'prop-types';
import styles from './Toggler.module.css'

const Toggler = ({theme,  toggleTheme }) => {
    let checked = theme === 'dark' ? 'checked' : '';
        return (
        <label className={styles.Toggler}>
            <input type="checkbox" defaultChecked={false} onChange={toggleTheme} checked={ checked }/>
            <span className={styles.Slider}></span>
        </label>
    );
};
Toggler.propTypes = {
    theme: string.isRequired,
    toggleTheme: func.isRequired,
}
export default Toggler;
