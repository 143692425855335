import React from 'react'
import {
  View,
  Link,
  NotFoundBoundary,
  useLoadingRoute,
} from 'react-navi'
import siteMetadata from '../siteMetadata'
import NotFoundPage from './NotFoundPage'
import LoadingIndicator from './LoadingIndicator'
import styles from './BlogLayout.module.css'
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./GlobalStyle";
import { lightTheme, darkTheme } from "./Themes"
import  { useDarkMode } from "./useDarkMode"
import Toggler from "./Toggler"


function BlogLayout({ blogRoot, isViewingIndex }) {
  const [theme, themeToggler] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  let loadingRoute = useLoadingRoute()

  return (
    <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles/>
        <div className={styles.container}>
        <Toggler theme={theme} toggleTheme={themeToggler}/>


          <LoadingIndicator active={!!loadingRoute} />

          {// Don't show the header on index pages, as it has a special header.
          !isViewingIndex && (
            <header>

              <h3 className={styles.title}>
                <Link href={blogRoot}>{siteMetadata.title}</Link>
              </h3>
            </header>
          )}
          <main>

            <NotFoundBoundary render={() => <NotFoundPage />}>
              <View />
            </NotFoundBoundary>
          </main>
        </div>
      </>
    </ThemeProvider>
  )
}

export default BlogLayout
